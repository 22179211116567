import { FormControl, FormHelperText } from "@mui/material";
import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import {
    styleBodyL,
    styleBodyM,
    styleBodyS,
    styleLabelM,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const labelFocus = theme("theme", {
    undefined: "--text-on-neutral-secondary",
    lightgray: "--text-on-neutral-secondary",
    blue: "--text-on-blue-secondary",
    cyan: "--text-on-cyan-secondary",
});

const textPlaceholder = theme("theme", {
    undefined: "--text-on-neutral-disabled",
    lightgray: "--text-on-neutral-disabled",
    blue: "--text-on-blue-disabled",
    cyan: "--text-on-cyan-disabled",
});

const textDisabled = theme("theme", {
    undefined: "--text-on-neutral-disabled",
    lightgray: "--text-on-neutral-disabled",
    blue: "--text-on-blue-disabled",
    cyan: "--text-on-cyan-disabled",
});

const textError = theme("theme", {
    undefined: "--text-on-neutral-inverted",
    lightgray: "--text-on-neutral-inverted",
    blue: "--text-on-coral-primary",
    cyan: "--text-on-neutral-inverted",
});

const textColor = theme("theme", {
    undefined: "--text-on-neutral-primary",
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
});

const borderColor = theme("theme", {
    undefined: "--field-on-neutral-default-border",
    lightgray: "--field-on-neutral-default-border",
    blue: "--field-on-blue-default-border",
    cyan: "--field-on-cyan-default-border",
});

const borderHover = theme("theme", {
    undefined: "--field-on-neutral-hover-border",
    lightgray: "--field-on-neutral-hover-border",
    blue: "--field-on-blue-hover-border",
    cyan: "--field-on-cyan-hover-border",
});

const borderError = theme("theme", {
    undefined: "--field-on-neutral-error-border",
    lightgray: "--field-on-neutral-error-border",
    blue: "--field-on-blue-error-border",
    cyan: "--field-on-cyan-error-border",
});

const borderDisabled = theme("theme", {
    undefined: "--field-on-neutral-disabled-border",
    lightgray: "--field-on-neutral-disabled-border",
    blue: "--field-on-blue-disabled-border",
    cyan: "--field-on-cyan-disabled-border",
});

export const Wrapper = styled(FormControl)`
    && {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 0;
        grid-column: col-start 1 / span 2;
        width: 100%;

        ${MQ.FROM_M} {
            grid-column: ${theme("displayOption", {
                Full: "auto / span 6",
                Half: "auto / span 3",
                OneThird: "auto / span 2",
                TwoThirds: "auto / span 4",
            })};
        }
    }
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const SelectAdornment = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.4375rem;
    margin-left: 0.4375rem;
    margin-right: 0.875rem;
    pointer-events: none;
    position: absolute;
    right: 0;

    ${MQ.FROM_XL} {
        gap: ${theme("size", {
            small: "0.4375rem",
            medium: "0.875rem",
        })};
        margin-right: ${theme("size", {
            small: "0.875rem",
            medium: "1.3125rem",
        })};
    }
`;

export const SelectIcon = styled(Icon)`
    color: var(${textColor});
    transition: transform 0.2s ease-in-out;
    ${theme("open", {
        true: css`
            transform: rotate3d(1, 0, 0, 180deg);
        `,
    })};
`;

export const ErrorIcon = styled(Icon)`
    color: var(${borderError});
`;

export const ErrorText = styled(FormHelperText)`
    && {
        ${styleLabelM};
        line-height: 1.3125rem;
        margin: 0 auto 0 0;
        z-index: 2;

        span {
            background-color: var(${borderError});
            border-radius: 0.25rem 0.25rem 0 0;
            color: var(${textError});
            display: block;
            margin-right: 2.625rem;
            padding: 0.25rem 0.4375rem 0.1875rem;
        }
    }
`;

export const HelperText = styled(FormHelperText)`
    && {
        ${theme("size", {
            small: styleBodyS,
            medium: styleBodyM,
        })};
        color: var(${labelFocus});
        margin: 0.4375rem 0 0;

        &.Mui-disabled {
            color: var(${textDisabled});
        }
    }
`;

export const sharedSelectLabelStyle = css`
    &-root {
        ${theme("size", {
            small: styleBodyM,
            medium: styleBodyL,
        })};
        color: var(${textColor});
        padding: 0 3.5rem 0 0.875rem;
        transform: ${theme("size", {
            small: "translate(0, 0.875rem)",
            medium: "translate(0, 1.3125rem)",
        })};

        ${MQ.FROM_XL} {
            padding: ${theme("size", {
                small: "0 0 0 0.875rem",
                medium: "0 0 0 1.3125rem",
            })};
            transform: ${theme("size", {
                small: "translate(0, 1.3125rem)",
                medium: "translate(0, 1.75rem)",
            })};
        }

        &.Mui-focused {
            ${theme("size", {
                small: styleBodyS,
                medium: styleBodyM,
            })};
            color: var(${labelFocus});
            transform: translate(0, 0.4375rem);

            ${MQ.FROM_XL} {
                transform: ${theme("size", {
                    small: "translate(0, 0.4375rem)",
                    medium: "translate(0, 0.875rem)",
                })};
            }
        }

        &.Mui-disabled {
            color: var(${textDisabled});
        }
    }

    &-shrink {
        ${theme("size", {
            small: styleBodyS,
            medium: styleBodyM,
        })};
        color: var(${labelFocus});
        transform: translate(0, 0.4375rem);

        ${MQ.FROM_XL} {
            transform: ${theme("size", {
                small: "translate(0, 0.4375rem)",
                medium: "translate(0, 0.875rem)",
            })};
        }

        /* prettier-ignore */
        &:has(+ .MuiOutlinedInput-root
                        .MuiInputBase-input:-webkit-autofill) {
                    color: var(--color-black);
                }
    }
`;

export const sharedSelectStyles = css`
    padding-right: 0;
    width: 100%;

    .MuiOutlinedInput {
        &-notchedOutline {
            border-color: var(${borderColor});
            top: 0;
            transition: border-color 0.2s ease-in-out;

            legend {
                height: 0;
                overflow: hidden;
                width: 0;
            }
        }
    }

    .MuiInputBase {
        &-input {
            ${theme("size", {
                small: styleBodyM,
                medium: styleBodyL,
            })};
            color: var(${textColor});
            line-height: 1em;
            min-height: 1.75rem;
            padding: ${theme("size", {
                small: "1.75rem 2rem 0 0.875rem !important",
                medium: "2.1875rem 2.875rem 0.4375rem 0.875rem !important",
            })};

            ${MQ.FROM_XL} {
                line-height: 1em;
                min-height: ${theme("size", {
                    small: "1.75rem",
                    medium: "2.1875rem",
                })};
                padding: ${theme("size", {
                    small: "2.1875rem 2.875rem 0.4375rem 0.875rem !important",
                    medium: "2.625rem 4.25rem 0.875rem 1.3125rem !important",
                })};
            }

            &.Mui-disabled {
                -webkit-text-fill-color: var(${textDisabled});
                color: var(${textDisabled});
            }

            &::placeholder {
                color: var(${textPlaceholder});
                opacity: 1;
            }
        }
    }

    .MuiPaper-root {
        background-color: var(--color-black-l95);
        border: 1px solid var(--color-black-o30);
        border-top: none;
        box-shadow: none;
    }
`;

export const sharedSelectActionStyles = css`
    &:hover {
        &:not(.Mui-disabled, .Mui-error) {
            .MuiOutlinedInput {
                &-notchedOutline {
                    border-color: var(${borderHover});
                }
            }
        }
    }

    &.Mui-error.Mui-focused,
    &.Mui-error {
        .MuiOutlinedInput {
            &-notchedOutline {
                border-color: var(${borderError});
                border-top-left-radius: 0;
            }
        }

        .MuiInputBase-input {
            padding-right: 6.375rem !important;

            ${MQ.FROM_XL} {
                padding-right: 7.75rem !important;
            }
        }
    }

    &.Mui-focused {
        .MuiOutlinedInput {
            &-notchedOutline {
                border-color: var(${borderHover});
                border-width: 1px;
                box-shadow: 0 0 0 0.4375rem var(--field-focus);
            }
        }
    }

    &.Mui-disabled {
        .MuiOutlinedInput {
            &-notchedOutline {
                border-color: var(${borderDisabled});
            }
        }

        svg {
            color: var(${textDisabled});
        }
    }
`;
