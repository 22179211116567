import { InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";
import { styled } from "styled-components";
import theme from "styled-theming";

import { styleBodyM, styleBodyS } from "style/components/Typography";

import {
    sharedSelectActionStyles,
    sharedSelectLabelStyle,
    sharedSelectStyles,
} from "../Select.styled";

export const Label = styled(InputLabel)`
    && {
        &.MuiInputLabel {
            ${sharedSelectLabelStyle}
        }
    }
`;

export const SelectItem = styled(MenuItem)`
    && {
        ${theme("size", {
            small: styleBodyS,
            medium: styleBodyM,
        })};
        color: var(--text-on-neutral-primary);
        &:hover,
        &:hover.Mui-selected {
            background-color: var(--color-cyan-o20);
        }

        &.Mui-selected {
            background-color: var(--color-black-l85);
        }
    }
`;

export const StyledSelect = styled(MuiSelect)`
    && {
        ${sharedSelectStyles};
        ${sharedSelectActionStyles};
    }
`;
