import { SelectChangeEvent } from "@mui/material";
import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import classNames from "classnames";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ThemeProvider } from "styled-components";

import evaluteVisibilityFromDependencies from "utils/evaluteVisibilityFromDependencies";
import generateValidationRules from "utils/generateValidationRules";

import { Label, SelectItem, StyledSelect } from "./SelectDefault.styled";

import {
    Wrapper,
    HelperText,
    SelectIcon,
    ErrorIcon,
    SelectAdornment,
    ErrorText,
    Container,
} from "../Select.styled";
import SelectProps from "../SelectProps";

const Select = ({
    id,
    identifier,
    label = "select",
    disabled,
    autoComplete,
    description,
    allowMultiSelect = false,
    displayOption = "Full",
    elementName,
    className,
    items,
    validators,
    control,
    dependencies,
    size = "medium",
    onChange,
    onFocus,
    setValue,
}: SelectProps): ReactElement => {
    const defaultValue = useMemo(
        () =>
            allowMultiSelect
                ? (items &&
                      items.length > 0 &&
                      items
                          .filter(
                              (item) => item.checked && item.checked === true,
                          )
                          ?.map((item) => item.value)) ||
                  []
                : (items &&
                      items.length > 0 &&
                      items.find(
                          (item) => item.checked && item.checked === true,
                      )?.value) ||
                  "",
        [allowMultiSelect, items],
    );

    const [values, setValues] = useState(defaultValue);
    const [open, setOpen] = useState(false);

    const formContext = useFormContext();
    const customChangeEventHandler = onChange;

    const handleChange = (
        event: SelectChangeEvent<any>, // eslint-disable-line @typescript-eslint/no-explicit-any,
        callback: (...event: any[]) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
    ): void => {
        const {
            target: { value },
        } = event;

        const newValues = allowMultiSelect
            ? typeof value === "string"
                ? value.split(",")
                : value
            : value;
        setValues(newValues);
        callback && callback(newValues);

        if (customChangeEventHandler) {
            customChangeEventHandler(event, newValues);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const classes = classNames(
        className,
        validators?.map((validator) => {
            return validator?.model?.validationCssClass || "";
        }),
    );

    const rules =
        validators && validators?.length > 0
            ? generateValidationRules(validators)
            : undefined;

    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;

    useEffect(() => {
        if (elementName && setValue)
            if (visibleFromDependencies) {
                setValue(elementName, defaultValue);
                setValues(defaultValue);
            } else {
                setValue(elementName, "");
                setValues("");
            }
    }, [defaultValue, elementName, setValue, visibleFromDependencies]);

    // If the formstate updates, make sure to update the internal state to reflect the changes
    const elementFormState =
        elementName && !!formContext && formContext.watch(elementName);
    useEffect(() => {
        if (formContext && elementFormState !== values) {
            setValues(elementFormState || "");
        }
    }, [elementFormState, values, formContext]);

    return (
        <MuiThemeProvider theme={createTheme()}>
            <ThemeProvider
                theme={{
                    displayOption: displayOption,
                    visibleFromDependencies: visibleFromDependencies,
                    open: open,
                    size,
                }}
            >
                {visibleFromDependencies && (
                    <Controller
                        name={elementName || ""}
                        control={control}
                        defaultValue={defaultValue || ""}
                        rules={rules}
                        disabled={disabled}
                        render={({
                            field: { onChange, value, ...field }, // eslint-disable-line unused-imports/no-unused-vars
                            fieldState: { error },
                        }) => (
                            <Wrapper className={className}>
                                {error && (
                                    <ErrorText>
                                        <span>{error.message}</span>
                                    </ErrorText>
                                )}
                                <Container>
                                    {label && (
                                        <Label
                                            disabled={disabled}
                                            error={!!error}
                                            id={
                                                (id || identifier) &&
                                                `${id || identifier}-label`
                                            }
                                        >
                                            {label}
                                        </Label>
                                    )}
                                    <StyledSelect
                                        labelId={
                                            (id || identifier) &&
                                            `${id || identifier}-label`
                                        }
                                        id={id || identifier}
                                        className={classes}
                                        label={label}
                                        error={!!error}
                                        disabled={disabled}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        multiple={allowMultiSelect}
                                        autoComplete={autoComplete}
                                        MenuProps={{ disablePortal: true }}
                                        IconComponent={() => null}
                                        endAdornment={
                                            <SelectAdornment>
                                                {error && (
                                                    <ErrorIcon icon="error28" />
                                                )}
                                                <SelectIcon icon="select28" />
                                            </SelectAdornment>
                                        }
                                        onChange={(event) =>
                                            handleChange(event, onChange)
                                        }
                                        value={values}
                                        {...field}
                                        onFocus={(
                                            event: React.FocusEvent<HTMLInputElement>,
                                        ) => {
                                            if (onFocus) {
                                                onFocus(event);
                                            }
                                        }}
                                    >
                                        {items?.map((item, index) => {
                                            return (
                                                <SelectItem
                                                    key={`radio-${index}`}
                                                    value={item.value || ""}
                                                    disabled={item.disabled}
                                                >
                                                    {item.caption}
                                                </SelectItem>
                                            );
                                        })}
                                    </StyledSelect>
                                </Container>
                                {description && (
                                    <HelperText
                                        disabled={disabled}
                                        error={false}
                                    >
                                        {description}
                                    </HelperText>
                                )}
                            </Wrapper>
                        )}
                    />
                )}
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(Select);
