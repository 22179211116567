import {
    Chip,
    Autocomplete as MuiAutoComplete,
    TextField,
} from "@mui/material";
import { styled, css } from "styled-components";
import theme from "styled-theming";

import {
    SelectAdornment,
    sharedSelectActionStyles,
    sharedSelectLabelStyle,
    sharedSelectStyles,
} from "components/Select/Select.styled";
import { styleBodyL, styleBodyM } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const textColor = theme("theme", {
    undefined: "--text-on-neutral-primary",
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
});

export const MuiTextField = styled(TextField)`
    .MuiInputLabel {
        ${sharedSelectLabelStyle};

        &-root {
            &.Mui-error {
                color: var(${textColor});
            }
        }
    }
`;

export const StyledAutoComplete = styled(MuiAutoComplete)`
    && {
        ${sharedSelectStyles}

        .MuiInputBase {
            &-root {
                padding: 0 !important;
                ${sharedSelectActionStyles}
            }

            &-input {
                height: 1.75rem;

                ${MQ.FROM_XL} {
                    height: ${theme("size", {
                        small: "1.75rem",
                        medium: "2.1875rem",
                    })};
                }
            }
        }

        .MuiAutocomplete {
            &-endAdornment {
                right: 0 !important;

                ${SelectAdornment} {
                    position: relative;
                }
            }
        }
        .MuiButtonBase {
            &-root {
                border-radius: 0;
                padding: 0;
                transform: none;
                margin-right: 0;

                &:hover {
                    background: none;
                }
            }
        }

        .MuiTouchRipple {
            &-root {
                display: none;
            }
        }
    }
`;

export const StyledChip = styled(Chip)`
    && {
        background-color: transparent;
        ${theme("size", {
            small: styleBodyM,
            medium: styleBodyL,
        })};
        border: 2px dashed blue;
        color: var(${textColor});
        padding: ${theme("size", {
            small: "1.75rem 0m 0 0.875rem !important",
            medium: "2.1875rem 0 0.4375rem 0.875rem !important",
        })};

        ${MQ.FROM_XL} {
            padding: ${theme("size", {
                small: "2.1875rem 0 0.4375rem 0.875rem !important",
                medium: "2.625rem 0 0.875rem 1.3125rem !important",
            })};
        }

        span {
            padding: 0;
        }
    }
`;

export const Part = styled.span<PartProps>`
    ${(props) =>
        props.highlight &&
        css`
            font-weight: bold;
        `}
`;

interface PartProps {
    readonly highlight: boolean;
}
