import { createFilterOptions } from "@mui/material/Autocomplete";
import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import match from "autosuggest-highlight/match"; //eslint-disable import/no-unresolved
import parse from "autosuggest-highlight/parse"; //eslint-disable import/no-unresolved
import classNames from "classnames";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ThemeProvider } from "styled-components";

import {
    SelectAdornment,
    SelectIcon,
    Wrapper,
    Container,
    ErrorIcon,
    ErrorText,
    HelperText,
} from "components/Select/Select.styled";
import SelectProps, { SelectItem } from "components/Select/SelectProps";
import evaluteVisibilityFromDependencies from "utils/evaluteVisibilityFromDependencies";
import generateValidationRules from "utils/generateValidationRules";

import {
    StyledAutoComplete,
    MuiTextField,
    StyledChip,
    Part,
} from "./SelectWithSearch.styled";

const SelectWithSearch = ({
    id,
    identifier,
    label = "select with search",
    disabled,
    autoComplete,
    description,
    allowMultiSelect = false,
    displayOption = "Full",
    elementName,
    className,
    items,
    validators,
    control,
    dependencies,
    size = "medium",
    onChange,
    onFocus,
    setValue,
}: SelectProps): ReactElement => {
    const defaultInputValue = useMemo(
        () =>
            allowMultiSelect
                ? (items &&
                      items.length > 0 &&
                      items
                          .filter(
                              (item) => item.checked && item.checked === true,
                          )
                          ?.map((item) => item.value)) ||
                  []
                : (items &&
                      items.length > 0 &&
                      items.find(
                          (item) => item.checked && item.checked === true,
                      )?.value) ||
                  "",
        [allowMultiSelect, items],
    );

    const [values, setValues] = useState(defaultInputValue);
    const [open, setOpen] = useState(false);

    const formContext = useFormContext();
    const customChangeEventHandler = onChange;

    const handleChange = (
        event: any, // eslint-disable-line @typescript-eslint/no-explicit-any,
        newValue: SelectItem | SelectItem[],
        callback: (...event: any[]) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
    ): void => {
        const value = Array.isArray(newValue)
            ? newValue.map((v) => v.value)
            : newValue.value;

        const newValues = allowMultiSelect
            ? typeof value === "string"
                ? value.split(",")
                : value
            : value;
        setValues(newValues);
        callback && callback(newValues);

        if (customChangeEventHandler) {
            customChangeEventHandler(event, newValues);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const rules =
        validators && validators?.length > 0
            ? generateValidationRules(validators)
            : undefined;

    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;

    useEffect(() => {
        if (elementName && setValue)
            if (visibleFromDependencies) {
                setValue(elementName, defaultInputValue);
                setValues(defaultInputValue);
            } else {
                setValue(elementName, "");
                setValues("");
            }
    }, [defaultInputValue, elementName, setValue, visibleFromDependencies]);

    // If the formstate updates, make sure to update the internal state to reflect the changes
    const elementFormState =
        elementName && !!formContext && formContext.watch(elementName);
    useEffect(() => {
        if (formContext && elementFormState !== values) {
            setValues(elementFormState || "");
        }
    }, [elementFormState, values, formContext]);

    const classes = classNames(
        className,
        validators?.map((validator) => {
            return validator?.model?.validationCssClass || "";
        }),
    );

    const filterOptions = createFilterOptions({
        ignoreAccents: false,
        matchFrom: "start",
    });

    return (
        <MuiThemeProvider theme={createTheme()}>
            <ThemeProvider
                theme={{
                    displayOption: displayOption,
                    visibleFromDependencies: visibleFromDependencies,
                    open: open,
                    size,
                }}
            >
                {visibleFromDependencies && items && (
                    <Controller
                        name={elementName || ""}
                        control={control}
                        rules={rules}
                        disabled={disabled}
                        render={({
                            field: { onChange, value, ...field }, // eslint-disable-line unused-imports/no-unused-vars
                            fieldState: { error },
                        }) => (
                            <Wrapper className={className}>
                                {error && (
                                    <ErrorText>
                                        <span>{error.message}</span>
                                    </ErrorText>
                                )}
                                <Container>
                                    {items && (
                                        <StyledAutoComplete
                                            {...field}
                                            disablePortal
                                            disableClearable
                                            options={items}
                                            filterOptions={filterOptions}
                                            value={
                                                values
                                                    ? items.find(
                                                          (item) =>
                                                              values ===
                                                              item.value,
                                                      ) ??
                                                      (allowMultiSelect
                                                          ? undefined
                                                          : "")
                                                    : allowMultiSelect
                                                      ? undefined
                                                      : ""
                                            }
                                            getOptionLabel={(option) => {
                                                return (
                                                    (option as SelectItem)
                                                        ?.caption || ""
                                                );
                                            }}
                                            className={classes}
                                            id={id || identifier}
                                            multiple={allowMultiSelect}
                                            autoComplete={!!autoComplete}
                                            onClose={handleClose}
                                            onOpen={handleOpen}
                                            onChange={(event, newValues) =>
                                                handleChange(
                                                    event,
                                                    newValues as SelectItem,
                                                    onChange,
                                                )
                                            }
                                            onFocus={(
                                                event: React.FocusEvent<HTMLInputElement>,
                                            ) => {
                                                if (onFocus) {
                                                    onFocus(event);
                                                }
                                            }}
                                            popupIcon={
                                                <SelectAdornment>
                                                    {error && (
                                                        <ErrorIcon icon="error28" />
                                                    )}
                                                    <SelectIcon icon="select28" />
                                                </SelectAdornment>
                                            }
                                            renderTags={(value) => {
                                                const extractedValues =
                                                    value.map(
                                                        (item) =>
                                                            (item as SelectItem)
                                                                .caption,
                                                    );
                                                const displayString =
                                                    extractedValues.join(", ");
                                                return (
                                                    <StyledChip
                                                        label={displayString}
                                                    />
                                                );
                                            }}
                                            isOptionEqualToValue={(
                                                option,
                                                selectedValue,
                                            ) =>
                                                (option as SelectItem).value ===
                                                ((selectedValue as SelectItem)
                                                    .value || "")
                                            }
                                            renderInput={(params) => (
                                                <MuiTextField
                                                    {...params}
                                                    label={label}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }}
                                                    error={!!error}
                                                />
                                            )}
                                            renderOption={(
                                                props,
                                                option,
                                                { inputValue },
                                            ) => {
                                                const matches = match(
                                                    (option as SelectItem)
                                                        .caption || "",
                                                    inputValue,
                                                    { insideWords: true },
                                                );
                                                const parts = parse(
                                                    (option as SelectItem)
                                                        .caption || "",
                                                    matches,
                                                );

                                                return (
                                                    <li {...props}>
                                                        <div>
                                                            {parts.map(
                                                                (
                                                                    part: {
                                                                        highlight: boolean;
                                                                        text: string;
                                                                    },
                                                                    index: number,
                                                                ) => (
                                                                    <Part
                                                                        key={
                                                                            index
                                                                        }
                                                                        highlight={
                                                                            part.highlight
                                                                        }
                                                                    >
                                                                        {
                                                                            part.text
                                                                        }
                                                                    </Part>
                                                                ),
                                                            )}
                                                        </div>
                                                    </li>
                                                );
                                            }}
                                        />
                                    )}
                                </Container>
                                {description && (
                                    <HelperText
                                        disabled={disabled}
                                        error={false}
                                    >
                                        {description}
                                    </HelperText>
                                )}
                            </Wrapper>
                        )}
                    />
                )}
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(SelectWithSearch);
